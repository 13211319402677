<template>
  <div
 
    class="navbar"
    :style="
      $store.state.theme.themeBackgroundPage == 1
        ? `background: var(--primary-color);`
        : `background: transparent;`
    "
  >
    <div v-if="!isShowing">
      <img
        :src="$store.state.domain + $store.state.theme.themeLogoLine"
        class="logo-img"
      />
    </div>
    <div v-else>
      <img
        :src="$store.state.domain + $store.state.theme.themeLogoLine"
        class="logo-img"
        @click="toProfile"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isShowing() {
      if (this.$cookies.get("hourglass_token")) return true;
      return false;
    }
  },
  data() {
    return {
      domain: process.env.VUE_APP_THEME_IMG_DOMAIN
    };
  },
  methods: {
    toProfile() {
      this.$router.push("/profile");
    },

  }
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
}
.logo-img {
  width: 140px;
  height: auto;
  padding: 0.75rem 0;
}
.btn-go-home {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}
.navbar {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  justify-content: center;
}
</style>
