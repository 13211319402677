<template>
  <div>
    <div :class="['select-custom ', { error: isValidate }, 'mb-3']">
      <label v-if="title" class="" style="color: #212529">
        {{ title }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <br v-if="title" />
      <b-form-select
        v-bind:options="options"
        :name="name"
        :size="size"
        v-bind:value="value"
        :required="required"
        :class="['btn-select']"
        @change="onDataChange"
        :valueField="valueField"
        :textField="textField"
        ref="input"
        :disabled="isDisplay"
        class="f-regular"
      ></b-form-select>
    </div>
    <div v-if="v && v.$error" class="mt-2">
      <span class="text-error" v-if="v.required == false">กรุณาเลือก</span>
      <span class="text-error" v-else-if="v.minValue == false">{{
        options[0][textField || "text"]
      }}</span>
      <span class="text-error" v-if="v.valueDefault == false">{{
        options[0][textField || "text"]
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    value: {
      required: false,
      type: Number | String
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    valueField: {
      required: false,
      type: String
    },
    textField: {
      required: false,
      type: String
    },
    isDisplay: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      selected: 0
    };
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat;
}

.select-custom {
  // margin-bottom: 15px;
  position: relative;
  white-space: nowrap;

  // padding-bottom: 15px;
}
.select-custom.error {
  border-color: red !important;
}
.select-custom.error > .btn-select {
  border-color: red !important;
}
.select-custom > label {
  color: gray;
  font-size: 16px;
  margin-bottom: 2px;
  width: 30%;
  margin: auto;
}
::v-deep .btn-select {
  color: #575757;
  background-color: transparent;
  border: 0px solid gray;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0px;
  padding: 5px 10px;
  cursor: pointer;
}
::v-deep .btn-select.custom-select-lg {
  height: 45px;
  font-size: 1rem;
}

::v-deep .btn-select:focus {
  border-color: gray;
}
::v-deep .btn-select > option {
  background-color: white;
  color: gray;
}
::v-deep .btn-select > option:checked {
  background-color: gray !important;
  color: white !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}
::v-deep .btn-select:disabled {
  color: #6c757d !important;
  background-color: #e9ecef !important;
}
.text-error {
  color: red;
  font-size: 14px;
}
</style>
