<template>
  <div id="app">
    <div
      :class="
        isShowing
          ? 'content'
          : background
          ? 'background-color'
          : 'background-image'
      "
    >
      <Header v-if="isLoadingTheme && !isShowHeader" />
      <div class="container-router"><router-view /></div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/layout/Header/Header";
export default {
  components: {
    Header
  },
  data() {
    return {
      setData: "",
      theme: {},
      isLoadingTheme: false,
      background: false
    };
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
    console.log(window);
    await this.getTheme();
  },
  computed: {
    isShowing() {
      if (
        this.$route.name == "validatetelephone" ||
        this.$route.name == "confirmotp" ||
        this.$route.name == "confirmotpedit" ||
        this.$route.name == "editprofile-telephone"
      )
        return true;
      return false;
    },
    isShowHeader() {
      // if (
      //   this.$route.name == "privilege" ||
      //   this.$route.name == "news" ||
      //   this.$route.name == "reward" ||
      //   this.$route.name == "news-detail" ||
      //   this.$route.name == "privilege-detail" ||
      //   this.$route.name == "reward-detail" ||
      //   this.$route.name == "reward-history-detail" ||
      //   this.$route.name == "privilege-set-detail" ||
      //   this.$route.name == "privilege-history-detail" ||
      //   this.$route.name == "point" ||
      //   this.$route.name == "coupon-history-detail" ||
      //   this.$route.name == "policy" ||
      //   this.$route.name == "terms"
      // )
      //   return true;
      return false;
    }
  },
  methods: {
    onScroll(e) {},
    invertColor(hex, bw) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    async getTheme() {
      this.$axios
        .get(`https://api-easymoney-campaign-prod.d-dots.com/api/Setting/theme`)
        .then(async data => {
          this.theme = data;
          this.$store.commit("setTheme", this.theme);

          this.background = data.result.themeBackgroundPage == 1 ? true : false;
          let bodyStyles = document.body.style;
          bodyStyles.setProperty(
            "--primary-color",
            data.result.themePrimaryColor
          );
          bodyStyles.setProperty(
            "--secondary-color",
            data.result.themeSecondaryColor
          );
          bodyStyles.setProperty(
            "--font-color",
            this.invertColor(data.result.themeSecondaryColor, true)
          );
          bodyStyles.setProperty(
            "--font-primary-color",
            this.invertColor(data.result.themePrimaryColor, true)
          );
          bodyStyles.setProperty(
            "--theme-background-color",
            data.result.themeSolidColor
          );
          bodyStyles.setProperty(
            "--theme-background-image",
            `url(${data.themeLogoDomain}${data.result.themeBackGround})`
          );
          this.isLoadingTheme = true;
        });
    }
  }
};
</script>
<style>
.content {
  position: fixed;
  background-color: #fff;
  width: 100%;
}
/* .background-b {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    0deg,
    var(--secondary-color) 0%,
    var(--primary-color) 100%
  );
} */
.background-color {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-color: var(--theme-background-color);
}
.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-image: var(--theme-background-image);
}
.container-router {
  margin-top: 80px;
}
</style>
