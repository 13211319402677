import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
import Vuex from "vuex";
Vue.use(VueCookies);
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    shortProfile: {
      picture: null,
      profile: null,
      email: "",
      first_name_th: "",
      last_name_th: "",
      first_name_en: "",
      last_name_en: "",
      nickname: "",
      telephone: "",
      birthday: "",
      gender: "",
      total_point: 0,
      home_address: "",
      town: "",
      alley: "",
      road: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: ""
    },
    theme: {
      themeDisableColor: "",
      themeLightPrimaryColor: "",
      themeLogoHomePage: "",
      themeLogoLoginPage: "",
      themePrimaryColor: "",
      themeSecondaryColor: ""
    },
    domain: ""
  },
  mutations: {
    setShortProfile: async function (state, val) {
      if (val) {
        state.shortProfile = val;
      } else {
        state.authenticate = false;
      }
    },
    setTheme: async function (state, val) {
      state.theme = val.result;
      state.domain = val.themeLogoDomain;
    }
  },
  actions: {
    getUserApi: async ({ commit, rootGetters }, payload) => {
      await axios({
        url: `${process.env.VUE_APP_API}/api/v1/user/GetUserProfile`,
        headers: rootGetters["api/headers"],
        method: "get"
      }).then(
        res => {
          if (res.result == 1) {
            commit("setShortProfile", res.detail);
          } else {
            commit("setShortProfile", false);
          }
        },
        error => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
          }
        }
      );
    }
  }
});
