import Vue from "vue";
import VueRouter from "vue-router";
const brand = process.env.VUE_APP_BRAND;
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/profile",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/validatetelephone",
    name: "validatetelephone",
    component: () => import("@/views/page/validateTelephone/index.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/confirmotp",
    name: "confirmotp",
    component: () => import("@/views/page/validateTelephone/confirmOTP.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/coupon",
    name: "coupon-redeem",
    component: () => import("@/views/page/coupon/Index.vue"),
    meta: {
      title: `Coupon`
    }
  },
  {
    path: "/coupon/:couponUser/:couponKey",
    name: "coupon-redeem-detail",
    component: () => import("@/views/page/coupon/detail/Detail.vue"),
    meta: {
      title: `Coupon`
    }
  },
  {
    path: "/couponhistory/:id",
    name: "coupon-history-detail",
    component: () => import("@/views/page/privilege/detail/CouponHistory.vue"),
    meta: {
      title: `Coupon`
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/page/register/index.vue"),
    meta: {
      title: `Register`
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/page/profile/index.vue"),
    meta: {
      title: `Profile`
    }
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: () => import("@/views/page/editprofile/index.vue"),
    meta: {
      title: `Profile`
    }
  },
  {
    path: "/privilege",
    name: "privilege",
    component: () => import("@/views/page/privilege/index.vue"),
    meta: {
      title: `Privilege`
    }
  },
  {
    path: "/privilege/:privilegeId",
    name: "privilege-detail",
    component: () => import("@/views/page/privilege/detail/Detail.vue"),
    meta: {
      title: `Privilege`
    }
  },
  {
    path: "/privilegehistory/:id",
    name: "privilege-history-detail",
    component: () => import("@/views/page/privilege/detail/DetailHistory.vue"),
    meta: {
      title: `Privilege`
    }
  },
  {
    path: "/privilege/set/:id",
    name: "privilege-set-detail",
    component: () => import("@/views/page/privilege/detail/PrivilegeSet.vue"),
    meta: {
      title: `Privilege`
    }
  },

  {
    path: "/point",
    name: "point",
    component: () => import("@/views/page/point/index.vue"),
    meta: {
      title: `Point`
    }
  },

  {
    path: "/review",
    name: "review",
    component: () => import("@/views/page/review/index.vue"),
    meta: {
      title: `Review`
    }
  },
  {
    path: "/telephone",
    name: "editprofile-telephone",
    component: () => import("@/views/page/editprofile/telephone.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/confirmotpedit",
    name: "confirmotpedit",
    component: () => import("@/views/page/editprofile/confirmotpedit.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/page/news/index.vue"),
    meta: {
      title: `News`
    }
  },
  {
    path: "/news/:newsId",
    name: "news-detail",
    component: () => import("@/views/page/news/detail/Detail.vue"),
    meta: {
      title: `News`
    }
  },
  {
    path: "/reward",
    name: "reward",
    component: () => import("@/views/page/reward/index.vue"),
    meta: {
      title: `Reward`
    }
  },
  {
    path: "/reward/:rewardId",
    name: "reward-detail",
    component: () => import("@/views/page/reward/detail/Detail.vue"),
    meta: {
      title: `Reward`
    }
  },
  {
    path: "/reward/privilege/:rewardId",
    name: "reward-detail",
    component: () =>
      import("@/views/page/reward/detail/DetailRewardPrivilege.vue"),
    meta: {
      title: `Reward`
    }
  },
  {
    path: "/rewardhistory",
    name: "reward-history",
    component: () => import("@/views/page/reward/detail/History.vue"),
    meta: {
      title: `Reward`
    }
  },
  {
    path: "/rewardhistory/:id",
    name: "reward-history-detail",
    component: () => import("@/views/page/reward/detail/DetailHistory.vue"),
    meta: {
      title: `Reward`
    }
  },
  {
    path: "/editaddress/:id",
    name: "reward-edit-address",
    component: () => import("@/views/page/reward/detail/DetailEditAddress.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/form",
    name: "campaign form",
    component: () => import("@/views/page/campaignform/index.vue"),
    meta: {
      title: `Form`
    }
  },
  {
    path: "/setting",
    name: "Setting PDPA",
    component: () => import("@/views/page/settingpdpa/index.vue"),
    meta: {
      title: `Setting`
    }
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/page/policy/index.vue"),
    meta: {
      title: `Policy`
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/page/terms/index.vue"),
    meta: {
      title: `Terms`
    }
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () => import("@/views/page/marketplace"),
    meta: {
      title: `Marketplace`
    }
  },
  {
    path: "/connectMarketplace",
    name: "Marketplace",
    component: () => import("@/views/page/marketplace/connectMarketplace"),
    meta: {
      title: `Marketplace`
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  console.log(to);
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    el => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});
export default router;
