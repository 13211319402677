import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "vue-datetime/dist/vue-datetime.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./style.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueCookies from "vue-cookies";
import { Datetime } from "vue-datetime";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import liff from "@line/liff";
import InfiniteLoading from "vue-infinite-loading";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import swal from "sweetalert";
import {
  faChevronRight,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faEdit,
  faHospitalSymbol,
  faBirthdayCake,
  faEnvelope,
  faPhoneAlt,
  faTicketAlt,
  faSyncAlt,
  faNewspaper,
  faChevronLeft,
  faPercentage,
  faGift,
  faTimesCircle,
  faCheckCircle,
  faEllipsisV,
  faTrophy,
  faHistory,
  faChevronDown,
  faChevronUp,
  faCalendarAlt,
  faPlus,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import VueCountdown from "@chenfengyuan/vue-countdown";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueSlickCarousel from "vue-slick-carousel";


import InputSelect from "@/components/input/InputSelect.vue";
import InputText from "@/components/input/InputText.vue";
const Slick = {
  install(Vue, options) {
    Vue.component("slick", VueSlickCarousel);
  }
};
Vue.use(Slick);

Vue.component("v-otp-input", OtpInput);
Vue.use({
  // this is the required "install" method for Vue plugins
  install(Vue) {
    Vue.swal = swal;
    Vue.prototype.$swal = swal;
  }
});
Vue.use(VueMeta);
Vue.use(InfiniteLoading, {
  /* options */
});
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.component("datetime", Datetime);
Vue.use(Vuelidate);
Vue.use(BootstrapVueIcons);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('input-select', InputSelect);
Vue.component('input-text', InputText);
library.add(
  faChevronRight,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faEdit,
  faChevronLeft,
  faGift,
  faTimesCircle,
  faCheckCircle,
  faHistory,
  faPlus,
  faStar,
  faHospitalSymbol,
  faBirthdayCake,
  faEnvelope,
  faPhoneAlt,
  faTicketAlt,
  faSyncAlt,
  faNewspaper,
  faPercentage,
  faEllipsisV,
  faTrophy,
  faChevronDown,
  faChevronUp,
  faCalendarAlt
);

Vue.config.productionTip = false;
// Vue.prototype.$baseUrl = baseUrl();
Vue.prototype.$apiKey = "ChAtA7Ds40p";
Vue.prototype.$axios = axios;
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm";
Vue.prototype.$liff = liff;
Vue.prototype.$liffUrl = process.env.VUE_APP_LIFF_URL;
Vue.prototype.$liff_ID_Login = process.env.VUE_APP_LIFF_ID_LOGIN;
Vue.prototype.$THEME = process.env.VUE_APP_THEME_IMG_DOMAIN;

//Add route permisssion
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    next();
  } else {
    const queryString = decodeURIComponent(window.location.search);
    const params = new URLSearchParams(queryString);
    const searchParam = params.get("liff.state");

    if (searchParam) {
      next(searchParam);
    } else {
      next();
    }
  }
});

// Add API Request
Vue.prototype.$axios.interceptors.request.use(function (config) {
  config.headers.Authorization = Vue.$cookies.isKey("hourglass_token")
    ? `Bearer ${Vue.$cookies.get("hourglass_token")}`
    : "";
  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  return config;
});
// Add API response
Vue.prototype.$axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return Promise.resolve(response.data);
  },
  function (error) {
    if (error.response.status == 401) {
      router.push({ name: "/" });
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
